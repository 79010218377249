import CSS from 'csstype';
import styled from 'styled-components';
import {
  color,
  ColorProps,
  display,
  DisplayProps,
  FontSizeProps,
  FontWeightProps,
  letterSpacing,
  LetterSpacingProps,
  lineHeight,
  LineHeightProps,
  maxWidth,
  MaxWidthProps,
  ResponsiveValue,
  space,
  SpaceProps,
  system,
  textStyle,
  TextStyleProps,
  typography,
  TypographyProps,
} from 'styled-system';
import { themeTransition } from '../../theme/themeTransition';

export type TextTransformProps = {
  textTransform?: ResponsiveValue<CSS.TextTransformProperty>;
};

export type TextProps = FontSizeProps &
  FontWeightProps &
  LineHeightProps &
  TextStyleProps &
  ColorProps &
  SpaceProps &
  DisplayProps &
  LetterSpacingProps &
  TypographyProps &
  TextTransformProps &
  MaxWidthProps;

export const Text = styled.div<TextProps>`
  ${system({
    textTransform: true,
  })};
  ${lineHeight};
  ${textStyle};
  ${letterSpacing}
  ${color};
  ${space};
  ${display};
  ${typography};
  ${maxWidth}

  box-decoration-break: clone;

  ${themeTransition('color')}
`;

Text.defaultProps = {
  color: 'text',
};
