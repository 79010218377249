import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import ErrorMessage from './ErrorMessage';
import { Translations } from '../../i18n/translations';

type SwitchProps = React.HTMLProps<HTMLInputElement> & {
  text?: ReactNode;
  touched?: boolean;
  error?: string;
  labelSize?: number[];
};

const Switch: FC<SwitchProps> = ({ ref, as, text, touched, error, labelSize, ...props }) => (
  <>
    <SwitchWrapper ref={ref as any} htmlFor={props.name}>
      <SwitchInput id={props.name} {...props} />
      <Toggle checked={props.checked} />
    </SwitchWrapper>
    {touched && error && <ErrorMessage error={error as keyof Translations} />}
  </>
);

const SwitchWrapper = styled.label`
  display: inline-flex;
  width: 80px;
  height: 40px;
  border: 1px solid;
  background-color: ${props => props.theme.colors.bg};
  cursor: pointer;
`;

const Toggle = styled.div<{ checked?: boolean }>`
  height: 100%;
  width: 50%;
  background-color: ${({ checked, theme }) =>
    checked ? theme.colors.primary : theme.colors.inverseBg};
  transform: ${({ checked }) => (checked ? 'translateX(100%)' : 'none')};
  transition: background-color 0.2s, transform 0.2s;
`;

const SwitchInput = styled.input.attrs({ type: 'checkbox' as string })`
  display: none;
`;

export default Switch;
