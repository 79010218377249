import React, { FC } from 'react';
import styled from 'styled-components';
import { flexbox, FlexboxProps, width, WidthProps } from 'styled-system';
import { color } from '../../theme/color';
import { themeTransition } from '../../theme/themeTransition';
import { NoDecorationLink } from './StyledLink';

type ButtonProps = FlexboxProps & WidthProps;
type ButtonMagneticProps = ButtonProps & {
  href?: string;
  target?: string;
  type?: 'button' | 'submit';
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const ButtonMagnetic: FC<ButtonMagneticProps> = ({ href, target, ...rest }) => {
  return (
    <NoDecorationLink href={href} target={target} style={{ zIndex: 0 }}>
      <Button {...rest} />
    </NoDecorationLink>
  );
};

const Button = styled.button<ButtonProps>`
  background-color: transparent;
  border: solid 1px ${props => props.theme.colors.text};
  color: ${props => props.theme.colors.text};
  display: inline-flex;
  padding: 0.5rem 2.5rem;
  font-weight: 300;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
  position: relative;
  ${flexbox};
  ${width};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: transparent;
    ${themeTransition(['transform', 'background-color'], 0.25)};
  }

  &:hover::before {
    transform: translate(5px, 5px);
    background-color: ${color.orange};
  }

  &:active {
    color: #fff;
  }

  &:disabled {
    color: #9f9f9f;
    border: solid 3px #9f9f9f;
    border-radius: 2rem;
    background-color: transparent;
    cursor: default;
  }
`;
