import { Link } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';
import { fontSize, FontSizeProps } from 'styled-system';
import { themeTransition } from '../../theme/themeTransition';
import { Box } from './Box';

export type NavLinkProps = FontSizeProps & {
  color?: string;
  underline?: 'thin' | 'large' | undefined;
  fontWeight?: number;
  active?: boolean;
};

const navLinkAnimation = keyframes`
from { transform: translateX(250%); opacity :0;}
to { transform: translateX(0%); opacity :1;}
`;

export const NavLink = styled(Link)<NavLinkProps>`
  position: relative;
  color: ${props => props.color || props.theme.colors.text};
  ${props =>
    props.active
      ? css`
          animation: ${navLinkAnimation} 0.5s cubic-bezier(0.16, 0.97, 0.46, 0.95) forwards;
          opacity: 0;

          &:nth-child(1) {
            animation-delay: 0.1s;
          }
          &:nth-child(2) {
            animation-delay: 0.15s;
          }
          &:nth-child(3) {
            animation-delay: 0.2s;
          }
          &:nth-child(4) {
            animation-delay: 0.25s;
          }
          &:nth-child(5) {
            animation-delay: 0.3s;
          }
          &:nth-child(6) {
            animation-delay: 0.35s;
          }
          &:nth-child(7) {
            animation-delay: 0.4s;
          }
          &:nth-child(8) {
            animation-delay: 0.45s;
          }
        `
      : ''}

  text-decoration: none;
  font-weight: ${props => props.fontWeight || 300};
  font-family: 'Quarto A, Quarto, Quarto B';
  font-weight: 500;
  ${fontSize};
  ${themeTransition('color')};

  @media screen and (min-width: 40em) {
    font-weight: ${props => props.fontWeight || 300};

    .navLink--active {
      font-weight: ${props => props.fontWeight || 500};
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -3px;
    border-radius: 5px;
    left: 0;
    right: 0;
    height: ${props => (props.underline === 'large' ? '4px' : '2px')};
    background-color: ${props => props.color || props.theme.colors.text};
    transform: scale(0, 1);
    transform-origin: right center;
    transition: transform 0.25s;
    will-change: transform;
  }

  &:hover::after,
  &.navLink--active::after,
  &[data-active]::after {
    transform: scale(1, 1);
    transform-origin: left center;
    will-change: transform;
  }

  &:active {
    color: ${props => props.theme.colors.text2};
  }
`;

//  Your links need classname. Additional link elem will not be animated.
export const AnimatedLinkWrapper = styled(Box)`
  .link-elem {
    color: ${props => props.theme.colors.text};
    text-decoration: none;
    font-weight: 300;
    position: relative;
    ${themeTransition('color')}

    @media screen and (min-width: 40em) {
      &::after {
        content: '';
        position: absolute;
        bottom: -3px;
        border-radius: 5px;
        left: 0;
        right: 0;
        height: 2px;
        background-color: ${props => props.color || props.theme.colors.text};
        transform: scale(0, 1);
        transform-origin: right center;
        transition: transform 0.25s;
        will-change: transform;
      }

      &:hover::after {
        transform: scale(1, 1);
        transform-origin: left center;
        will-change: transform;
      }
    }
  }
  .additional-link-elem {
    &:hover {
      ~ .link-elem {
        &::after {
          transform: scale(1, 1);
          transform-origin: left center;
          will-change: transform;
        }
      }
      .link-elem {
        &::after {
          transform: scale(1, 1);
          transform-origin: left center;
          will-change: transform;
        }
      }
    }
  }
`;
NavLink.defaultProps = {
  activeClassName: 'navLink--active',
  getProps: ({ isPartiallyCurrent }: { isPartiallyCurrent: boolean }) => {
    return isPartiallyCurrent ? { 'data-active': true } : {};
  },
};
