import { useFormik } from 'formik';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { FC, useState, useEffect, useContext } from 'react';
import { globalHistory } from '@reach/router';
import styled from 'styled-components';
import {
  GDPRModalConfigQuery,
  GDPRModalConfigQuery_sanityConfig,
  GDPRModalConfigQuery_sanityConfig_gdprModal,
} from '../__generated__/graphql';

import localStorageUtils from '../utils/localStorage';
import { Box } from './common/Box';
import { ButtonMagnetic } from './common/ButtonMagnetic';
import { H2 } from './common/Heading';
import Modal from './common/Modal';
import { UnderlinedLink } from './common/StyledLink';
import Switch from './common/Switch';
import { Text } from './common/Text';
import { GlobalContext } from './GlobalContext';
import { themes } from '../theme/theme';
import { useLocale } from './CurrentLocale';
import { extractWithLocale } from '../utils/extractWithLocale';
import { MiniBlockContentRenderer } from './common/MiniBlockContentRenderer';

enum TYPES {
  BASIC,
  ADVANCED,
}

const cookiesPathname = '/cookies';

const GDPRModal = () => {
  const { sanityConfig } = useStaticQuery<GDPRModalConfigQuery>(query);
  const locale = useLocale();
  const { context, dispatch } = useContext(GlobalContext);
  const [type, setType] = useState(TYPES.BASIC);
  const data = sanityConfig?.gdprModal;

  useEffect(() => {
    const consent = JSON.parse(localStorageUtils.get('cookies') || '{}');
    const isCookiesPage = window.location.pathname === cookiesPathname;

    if (!consent.necessary && !isCookiesPage) {
      dispatch({ type: 'isGDPRModalOpen', payload: true });
    }

    if (consent.analytics) {
      window.dataLayer?.push({ cookieThirdparty: 'granted' });
    }

    return globalHistory.listen(({ location }) => {
      if (location.pathname === cookiesPathname) {
        dispatch({ type: 'isGDPRModalOpen', payload: false });
      }
    });
  }, [dispatch]);

  const form = useFormik({
    initialValues: {
      necessary: true,
      analytics: true,
    },
    onSubmit: () => {},
  });

  const onAccept = () => {
    localStorageUtils.set('cookies', JSON.stringify(form.values));

    if (form.values.analytics) {
      dataLayer.push({ cookieThirdparty: 'granted' });
      dataLayer.push({ event: 'cookieThirdparty-access-granted' });
    } else {
      dataLayer.push({ cookieThirdparty: 'denied' });
    }

    dispatch({ type: 'isGDPRModalOpen', payload: false });
  };

  if (!data) {
    return null;
  }

  const renderContent = () => {
    const isBasic = type === TYPES.BASIC;

    if (isBasic) {
      return (
        <BasicConsent
          data={data}
          onShowDetails={() => setType(TYPES.ADVANCED)}
          onAccept={onAccept}
        />
      );
    }

    return (
      <AdvancedConsent
        data={data}
        form={form}
        onBack={() => setType(TYPES.BASIC)}
        onAccept={onAccept}
      />
    );
  };

  const heading = extractWithLocale(data?.heading, locale);

  return (
    <>
      <Modal open={context.isGDPRModalOpen} closeOnBackdrop={false} onClose={onAccept}>
        <H2 fontWeight="bold" marginBottom={20} color={themes.light.colors.text}>
          {heading}
        </H2>
        <StyledForm>{renderContent()}</StyledForm>
      </Modal>
    </>
  );
};

export default GDPRModal;

interface BasicConsentProps {
  data: GDPRModalConfigQuery_sanityConfig_gdprModal;
  onShowDetails: () => void;
  onAccept: () => void;
}

const BasicConsent: FC<BasicConsentProps> = ({ data, onShowDetails, onAccept }) => {
  const locale = useLocale();

  return (
    <>
      <MiniBlockContentRenderer body={extractWithLocale(data?.content, locale)} />
      <Box display="flex" justifyContent="space-between" marginTop={20}>
        <UnderlinedLink color={themes.light.colors.text} onClick={onShowDetails}>
          {extractWithLocale(data?.buttonAdvanced, locale)}
        </UnderlinedLink>
        <ButtonMagnetic onClick={onAccept} theme={themes.light}>
          {extractWithLocale(data?.buttonAccept, locale)}
        </ButtonMagnetic>
      </Box>
    </>
  );
};

interface AdvancedConsentProps {
  data: GDPRModalConfigQuery_sanityConfig_gdprModal;
  form: any;
  onAccept: () => void;
  onBack: () => void;
}

const AdvancedConsent: FC<AdvancedConsentProps> = ({ data, form, onBack, onAccept }) => {
  const locale = useLocale();

  return (
    <>
      <ConsentRow
        title={extractWithLocale(data?.cookiesHeading, locale)}
        trigger={
          <Text color="orange" fontWeight="bold">
            {extractWithLocale(data?.cookiesStatus, locale)}
          </Text>
        }
        description={extractWithLocale(data?.cookiesContent, locale)}
      />

      <ConsentRow
        title={extractWithLocale(data?.analyticsHeading, locale)}
        trigger={
          <Switch
            name="analytics"
            checked={form.values.analytics}
            error={form.errors.analytics}
            touched={form.touched.analytics}
            onChange={form.handleChange}
          />
        }
        description={extractWithLocale(data?.analyticsContent, locale)}
      />

      <Box display="flex" justifyContent="space-between">
        <UnderlinedLink
          style={{ marginRight: 20 }}
          onClick={onBack}
          color={themes.light.colors.text}
        >
          {extractWithLocale(data?.buttonBack, locale)}
        </UnderlinedLink>
        <ButtonMagnetic onClick={onAccept} theme={themes.light}>
          {extractWithLocale(data?.buttonSave, locale)}
        </ButtonMagnetic>
      </Box>
    </>
  );
};

interface ConsentRowProps {
  title: string | null;
  trigger: JSX.Element;
  description: string;
}

const ConsentRow: FC<ConsentRowProps> = ({ title, trigger, description }) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text fontWeight="500" color={themes.light.colors.text}>
          {title}
        </Text>
        {trigger}
      </Box>
      <Box marginY={20}>
        <MiniBlockContentRenderer body={description} />
      </Box>
    </>
  );
};

const StyledForm = styled.form`
  max-width: 600px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const query = graphql`
  query GDPRModalConfigQuery {
    sanityConfig {
      gdprModal {
        heading {
          sv
          en
        }
        content {
          sv: _rawSv
          en: _rawEn
        }
        buttonAdvanced {
          sv
          en
        }
        buttonAccept {
          sv
          en
        }
        cookiesHeading {
          sv
          en
        }
        cookiesStatus {
          sv
          en
        }
        cookiesContent {
          sv: _rawSv
          en: _rawEn
        }
        analyticsHeading {
          sv
          en
        }
        analyticsContent {
          sv: _rawSv
          en: _rawEn
        }
        buttonBack {
          sv
          en
        }
        buttonSave {
          sv
          en
        }
      }
    }
  }
`;
