import React from 'react';
import styled from 'styled-components';
import Div100vh from 'react-div-100vh';
import { themeTransition } from '../theme/themeTransition';
import { Box } from './common/Box';
import { useLocale } from './CurrentLocale';

export type Setter<S> = S | ((prevState: S) => S);

export type MenuProps = {
  active: boolean;
  onActiveChange: (active: Setter<boolean>) => void;
};

export const Menu: React.FC<MenuProps> = ({ children, active, onActiveChange }) => {
  const locale = useLocale();

  return (
    <MenuWrapper>
      <Box
        position="relative"
        display={['block', 'block', 'none']}
        zIndex={101}
        width="2.75rem"
        height="2.75rem"
      >
        <MenuButton onClick={() => onActiveChange(a => !a)} active={active} label="Toggle menu" />
      </Box>
      <OuterWrapper
        position={['fixed', 'static']}
        height={['100vh', 'auto']}
        width={['100vw', 'auto']}
        active={active}
        onClick={() => onActiveChange(false)}
      >
        <MenuContentWrapper
          display="flex"
          flexDirection={['column', 'column', 'row']}
          justifyContent="space-between"
          alignItems="baseline"
          pt={[6, 6, 0]}
          px={[4, 4, 0]}
          active={active}
          onClick={(ev: React.MouseEvent<HTMLButtonElement>) => ev.stopPropagation()}
          as={Div100vh}
        >
          {children}
        </MenuContentWrapper>
      </OuterWrapper>
    </MenuWrapper>
  );
};

const MenuButton: React.FC<{
  onClick: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  label: string;
  active: boolean;
}> = ({ onClick, label, active }) => {
  return (
    <StyledButton active={active} onClick={onClick} aria-label={label}>
      <ButtonLine active={active} />
      <ButtonLine active={active} />
    </StyledButton>
  );
};

const MenuWrapper = styled.div`
  position: relative;
`;

const MenuContentWrapper = styled(Box)<{ active: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 70vw;
  background-color: ${props => props.theme.colors.bg};
  min-height: -webkit-fill-available;

  transform: translateX(${props => (props.active ? `0` : '100%')});
  visibility: ${props => (props.active ? `visible` : 'hidden')};
  opacity: ${props => (props.active ? `1` : '0')};
  transition: transform 0.4s cubic-bezier(0.16, 0.97, 0.46, 0.95),
    background-color 0.4s cubic-bezier(0.16, 0.97, 0.46, 0.95);

  @media screen and (min-width: 52em) {
    height: auto !important;
    min-height: unset !important;
  }

  @media screen and (min-width: 52em) {
    position: static;
    height: auto;
    width: auto;
    min-height: unset !important;
    background-color: transparent;
    transform: none;
    transition: none;
    visibility: visible;
    opacity: 1;
  }
`;

const OuterWrapper = styled(Box)<{ active: boolean }>`
  z-index: 99;
  top: 0;
  right: 0;
  pointer-events: ${props => (props.active ? 'auto' : 'none')};

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: ${props => (props.active ? 1 : 0)};
    transition: opacity 0.4s cubic-bezier(0.16, 0.97, 0.46, 0.95);
  }

  @media screen and (min-width: 40em) {
    pointer-events: auto;

    &::before {
      opacity: 0;
      transition: none;
    }
  }
`;

const StyledButton = styled.button<{ active: boolean }>`
  position: relative;
  height: 2.75rem;
  width: 2.75rem;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  ${themeTransition('background-color')}
`;

const ButtonLine = styled.div<{ active: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 1px;
  width: 55%;
  transform: translateX(-50%);
  transition: ${props => (props.active ? 'transform 0.1s' : 'transform 0.1s 0.15s')};
  transition-timing-function: cubic-bezier(0.16, 0.97, 0.46, 0.95);

  &::after {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => props.theme.colors.text};
    transition: ${props => (props.active ? 'transform 0.1s 0.15s' : 'transform 0.1s')};
    transition-timing-function: cubic-bezier(0.16, 0.97, 0.46, 0.95);
  }

  &:first-child {
    transform: translateX(-50%) translateY(${props => (props.active ? '0px' : '-3px')});

    &::after {
      transform: rotate(${props => (props.active ? '-45deg' : '0deg')});
    }
  }

  &:last-child {
    transform: translateX(-50%) translateY(${props => (props.active ? '0px' : '3px')});

    &::after {
      transform: rotate(${props => (props.active ? '45deg' : '0deg')});
    }
  }
`;
