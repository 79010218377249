import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../i18n/useTranslation';
import { color } from '../theme/color';
import { bodyPadding, grid } from '../theme/theme';
import { getLocalizedUri } from '../utils/getLocalizedLink';
import { NavPage, OfficeQuery } from '../__generated__/graphql';
import { Box } from './common/Box';
import { Grid } from './common/Grid';
import { InnerGrid } from './common/InnerGrid';
import { NavLink } from './common/NavLink';
import { StyledLink } from './common/StyledLink';
import { useLocale } from './CurrentLocale';
import { Office } from './Office';

const StyledFooter = Box.withComponent('footer');

const Footer: React.FC<{ navigation: NavPage[] }> = ({ navigation }) => {
  const officeQuery = useStaticQuery<OfficeQuery>(query);
  const locale = useLocale();
  const t = useTranslation();

  const offices = officeQuery.allSanityOffice!.nodes!;

  return (
    <StyledFooter
      display="flex"
      flexDirection={['column', 'row']}
      justifyContent="space-between"
      alignItems="center"
      pt={4}
      pb={5}
      px={bodyPadding}
      bg={color.black}
      color={color.white}
      gridColumn="1 / -1"
      position="relative"
      zIndex="10"
    >
      <Grid gridTemplateColumns={grid}>
        <InnerGrid columns={[1, 2, 4]}>
          {offices.map(o => (
            <Office key={o.id} office={o} />
          ))}
          <Box display="flex" justifyContent={['flex-start', 'flex-start', 'flex-end']}>
            <Box display="inline">
              <Box>
                <NavLink to={getLocalizedUri('/privacy', locale)} color="#fff" fontSize={3}>
                  {t('privacyPolicy')}
                </NavLink>
              </Box>
              <Box mb={3}>
                <NavLink to={getLocalizedUri('/cookies', locale)} color="#fff" fontSize={3}>
                  {t('cookiesPolicy')}
                </NavLink>
              </Box>
              <Box>
                <NavLink as="a" href="https://www.facebook.com/esattose/" color="#fff" fontSize={3}>
                  Facebook
                </NavLink>
              </Box>
              <Box>
                <NavLink
                  as="a"
                  href="https://www.linkedin.com/company/esatto"
                  color="#fff"
                  fontSize={3}
                >
                  Linkedin
                </NavLink>
              </Box>
              <Box>
                <NavLink
                  as="a"
                  href="https://www.instagram.com/esatto.se/"
                  color="#fff"
                  fontSize={3}
                >
                  Instagram
                </NavLink>
              </Box>
            </Box>
          </Box>
        </InnerGrid>
      </Grid>
    </StyledFooter>
  );
};

const FooterLink = styled(StyledLink)`
  font-family: 'Quarto A, Quarto, Quarto B';
  font-size: 1.125rem;
  color: #fff;

  &:hover {
  }
`;

const query = graphql`
  query OfficeQuery {
    allSanityOffice {
      nodes {
        ...Office
      }
    }
  }

  fragment Office on SanityOffice {
    id
    contact {
      firstName
      title
      phone
    }
    location {
      address
      postalCode
      city
      country
      coordinates {
        lat
        lng
        alt
      }
    }
  }
`;

export default Footer;
