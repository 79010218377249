import React from 'react';
import { useTranslation } from '../../i18n/useTranslation';
import { Text } from '../common/Text';
import { Translations } from '../../i18n/translations';

const ErrorMessage = ({ absolute, error }: { absolute?: boolean; error: keyof Translations }) => {
  const t = useTranslation();

  return (
    <Text
      style={{ position: absolute ? 'absolute' : 'static', bottom: -25 }}
      color="error"
      fontSize={1}
    >
      {t(error)}
    </Text>
  );
};

export default ErrorMessage;
