import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonHover } from './common/ButtonHover';
import { H2 } from './common/Heading';
import { Text } from './common/Text';
import smile from '../images/icons/esatto-talks.svg';
import chevron from '../images/icons/chevron.svg';
import { graphql, useStaticQuery } from 'gatsby';
import { EsattoTalksConfigQuery } from '../__generated__/graphql';
import { useLocale } from './CurrentLocale';
import { extractWithLocale } from '../utils/extractWithLocale';
import { MiniBlockContentRenderer } from './common/MiniBlockContentRenderer';
import { theme, themes } from '../theme/theme';
import { useShowBubble } from './TalksProvider';
import { getLocalizedUri } from '../utils/getLocalizedLink';
import { Box } from './common/Box';

export const TalksBubble: React.FC = () => {
  const data = useStaticQuery<EsattoTalksConfigQuery>(query);
  const locale = useLocale();

  const showBubble = useShowBubble();

  const show =
    (!!data.sanityConfig?.esattoTalks?.show && showBubble !== false) || showBubble === true;
  const [expanded, setExpanded] = useState(false);

  const expand = () => {
    setExpanded(!expanded);
  };

  const header = extractWithLocale(data.sanityConfig?.esattoTalks?.header, locale);
  const content = extractWithLocale(data.sanityConfig?.esattoTalks?.content, locale);
  const buttonText = extractWithLocale(data.sanityConfig?.esattoTalks?.buttonText, locale);
  const buttonLink = extractWithLocale(data.sanityConfig?.esattoTalks?.buttonLink?.slug, locale)
    ?.current;

  const bubbleIcon = expanded ? chevron : smile;
  const buttonUrl = getLocalizedUri(buttonLink, locale);

  return <>
    {show && <Bubble icon={bubbleIcon} expanded={expanded} onClick={expand} />}
    {show && expanded && <BubbleCard>
      <Text
        fontSize={[3, 4]}
        ml={'2px'}
        fontFamily="Handsome"
        style={{ display: 'inline-flex' }}
        color={themes.light.colors.text}
      >
        Talks
      </Text>

      {header && <H2 style={{ borderBottom: '1px solid #000', paddingBottom: '15px', marginBottom: '15px', lineHeight: '36px' }} color={themes.light.colors.text}>
        {header}
      </H2>}

      <MiniBlockContentRenderer body={content} />

      <Box style={{ marginTop: 20 }}>
        <ButtonHover href={buttonUrl} style={{ width: '100%', marginTop: '1.5rem', marginBottom: 10, color: themes.light.colors.text }}>
          {buttonText}
        </ButtonHover>
      </Box>
    </BubbleCard>}
  </>
}

const Bubble = styled.div<{ icon: String, expanded: Boolean }>`
  position: fixed;
  bottom: 20px;
  right: 16px;
  height: 100px;
  width: 100px;
  z-index: 15;
  background-color: ${theme.colors.greenPrimary};
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${props => (props.expanded ? 'unset' : '55%')};
  border: 2px solid ${theme.colors.greenPrimary};
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: #fff;
  }

  @media (min-width: 650px) and (min-height: 850px) {
    bottom: 25px;
    height: 150px;
    width: 150px;
    background-size: unset;
  }

  @media screen and (min-width: 52em) {
    right: 1rem;
  }

  @media screen and (min-width: 64em) {
    right: 2rem;
  }

  @media screen and (min-width: 80em) {
    right: 4rem;
  }
`;

const BubbleCard = styled.div`
  position: fixed;
  bottom: 135px;
  right: 16px;
  margin-left: 16px;
  max-width: 400px;
  z-index: 99;
  background-color: #fff;
  box-shadow: 0 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-top: 4px solid ${theme.colors.greenPrimary};

  @media (min-width: 650px) and (min-height: 850px) {
    bottom: 200px;
    width: 400px;
    padding: 30px;
  }

  @media screen and (min-width: 40em) {
    right: 1rem;
  }

  @media screen and (min-width: 64em) {
    right: 2rem;
  }

  @media screen and (min-width: 80em) {
    right: 4rem;
  }

  @media (min-width: 650px) and (max-height: 850px) {
    bottom: 20px;

    @media screen and (min-width: 40em) {
      right: 8rem;
    }

    @media screen and (min-width: 64em) {
      right: 10rem;
    }

    @media screen and (min-width: 80em) {
      right: 12rem;
    }
  }
`;

const query = graphql`
  query EsattoTalksConfigQuery {
    sanityConfig {
      esattoTalks {
        show
        header {
          sv
          en
        }
        content {
          sv: _rawSv
          en: _rawEn
        }
        buttonText {
          sv
          en
        }
        buttonLink {
          id
          slug {
            sv {
              current
            }
            en {
              current
            }
          }
        }
      }
    }
  }
`;
