import React, { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styled, { keyframes } from 'styled-components';
import { useWindowSize } from '../../hooks/useWindowSize';
import kernerImage from '../../images/kerner/kerner.png';

type Particle = {
  index: number;
  duration: number;
  rotation: number;
  x: number;
  size: number;
};

const KernerRain: React.FC = () => {
  const [active, setActive] = useState(false);
  const [score, setScore] = useState(0);
  const [particles, setParticles] = useState<Particle[]>([]);
  const { height, width } = useWindowSize();
  const animatedScore = useSpring({ score });

  useKonamiCode(() => {
    setActive(true);
  });

  useEffect(() => {
    if (width == 0) return () => {};
    if (!active) {
      setParticles([]);
      return;
    }

    let currentIndex = 0;
    let timeouts: { [key: string]: number } = {};

    const timeout = setInterval(() => {
      const index = ++currentIndex;
      const particle: Particle = {
        index,
        rotation: Math.random() * 1440 - 720,
        duration: Math.random() * 8000 + 2000,
        x: Math.random() * width,
        size: Math.random() * 1.5 - 1,
      };

      timeouts[index] = setTimeout(() => {
        setParticles(current => current.filter(c => c.index !== index));
      }, particle.duration);

      setParticles(current => [...current, particle]);
    }, 200);

    return () => {
      clearInterval(timeout);
      setParticles([]);

      Object.values(timeouts).forEach(t => clearTimeout(t));
    };
  }, [active, width]);

  if (!active) return null;

  return (
    <div data-particles>
      <ScoreContainer>
        <Score>
          <AnimatedDiv>
            {animatedScore.score.interpolate(x => x.toFixed(0).padStart(6, '0'))}
          </AnimatedDiv>
        </Score>
        {score > 100000 && (
          <ContactKerner>
            <a href="mailto:magnus.kerner@esatto.se">
              Du verkar gilla det här
              <br />
              Kontakta Magnus Kerner
            </a>
          </ContactKerner>
        )}
      </ScoreContainer>
      {particles.map(particle => (
        <ParticleComponent
          key={particle.index}
          particle={particle}
          height={height}
          onClick={() => {
            setParticles(p => p.filter(item => item.index !== particle.index));
            setScore(s => s + Math.round(Math.random() * 5000));
          }}
        />
      ))}
    </div>
  );
};

const animateIn = keyframes`
from { transform: scale(40)
}`;

const ScoreContainer = styled.div`
  position: fixed;
  top: 1rem;
  right: 2rem;
  z-index: 100000;
`;

const Score = styled.div`
  font-size: 4rem;
  width: 16rem;
  text-align: right;
  font-family: 'Comic Sans MS';
  background-color: #f0f;
  color: #0f0;
  z-index: 10000;
  border-radius: 10%;
  padding: 0.5rem 1rem;
  transform-origin: top right;
  animation: ${animateIn} 1s cubic-bezier(0.16, 0.97, 0.46, 0.95);
`;

const ContactKerner = styled.div`
  background-color: #0ff;
  color: #f00;
  right: 1rem;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 15% / 4%;
  z-index: 10000;
`;

export const ParticleComponent: React.FC<{
  particle: Particle;
  height: number;
  onClick: () => void;
}> = ({ particle, height, onClick }) => {
  const springProps = useSpring({
    from: {
      transform: `translateY(-300px) rotate(0deg) scale(${particle.size})`,
      position: 'fixed',
      top: 0,
      left: particle.x,
      zIndex: 10000,
    },
    to: {
      transform: `translateY(${height}px) rotate(${particle.rotation}deg) scale(${particle.size})`,
      position: 'fixed',
      top: 0,
      left: particle.x,
      zIndex: 10000,
    },
    config: {
      duration: particle.duration,
    },
  });

  return (
    <AnimatedDiv style={springProps} onClick={onClick}>
      <img src={kernerImage} draggable={false} />
    </AnimatedDiv>
  );
};

const AnimatedDiv = animated('div');

// up up down down left right left right B A

const useKonamiCode = (onActivate: () => void) => {
  const [keys, setKeys] = useState<string[]>([]);

  useEffect(() => {
    if (keys.join('') === 'uuddlrlrba') {
      onActivate();
      setKeys([]);
    }
  }, [keys, onActivate]);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      const key = keyMap[event.keyCode] ?? null;

      if (key == null) {
        setKeys([]);
      } else {
        setKeys(current => {
          if (current.length >= 10) {
            return current.concat([key]).slice(1, 11);
          }

          return current.concat([key]);
        });
      }
    };

    window.addEventListener('keydown', handler, { passive: true });

    return () => {
      window.removeEventListener('keydown', handler);
    };
  });
};

const keyMap: { [key: number]: string } = {
  38: 'u',
  40: 'd',
  37: 'l',
  39: 'r',
  66: 'b',
  65: 'a',
};

export default KernerRain;
