import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { extractWithLocale } from '../../utils/extractWithLocale';
import { getLocalizedLink } from '../../utils/getLocalizedLink';
import { AvailableArticlesQuery, NavPage } from '../../__generated__/graphql';
import { useLocale } from '../CurrentLocale';
import { Box } from './Box';
import { Grid } from './Grid';
import { NavLink } from './NavLink';

export type NavigationProps = {
  pages: NavPage[];
  color?: string;
  active: boolean;
};

export const Navigation: React.FC<NavigationProps> = ({ pages, color, active }) => {
  const locale = useLocale();

  const { articles } = useStaticQuery<AvailableArticlesQuery>(query);

  const noArticlesInLocale =
    articles.nodes.filter(article => article.slug && article.slug[locale]).length < 1
      ? true
      : false;

  if (noArticlesInLocale)
    pages = pages.filter(
      page => page.__typename !== 'SanityRelativeUrl' && page.pageType !== 'articlePage'
    );

  return (
    <Box zIndex={2}>
      <Grid
        as="nav"
        gridAutoFlow={['row', 'row', 'column']}
        gridGap={[3, 3, 5]}
        display={active ? 'grid' : ['none', 'grid']}
      >
        {pages.map(item => {
          const slug = item.__typename === 'SanityPage' ? item.slug : item.relativeUrl;

          if (slug?.[locale] === null) return null;

          const link = getLocalizedLink(item, locale);

          const title =
            item.__typename === 'SanityPage'
              ? extractWithLocale(item.name, locale) || ''
              : extractWithLocale(item.title, locale);

          const id = (item.__typename === 'SanityPage' ? item.id : item._key) ?? '';

          return (
            <NavLink fontSize={[5, 5, 4]} active={active} key={id} to={link} color={color}>
              {title}
            </NavLink>
          );
        })}
      </Grid>
    </Box>
  );
};

const query = graphql`
  query AvailableArticlesQuery {
    articles: allSanityArticle {
      nodes {
        slug {
          en {
            current
          }
          sv {
            current
          }
        }
      }
    }
  }
`;
