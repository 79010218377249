import { useLocale } from '../components/CurrentLocale';
import { PageContext } from '../utils/PageProps';
import { en, sv, Translations } from './translations';

const availableLanguages: { [key in PageContext['locale']]: Translations } = {
  sv,
  en,
};

export const useTranslation = () => {
  const locale = useLocale();

  const lang = availableLanguages[locale];

  return (key: keyof Translations) => lang[key];
};
