import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { ReactComponent as LogoIcon } from '../../images/logo.svg';
import { themeTransitionObject } from '../../theme/themeTransition';
import { Box } from './Box';

export const Logo: React.FC<{
  color?: string;
  maxWidth?: string | number | Array<string | number>;
}> = ({ color, maxWidth = 150 }) => {
  const ctxColor = useContext(ThemeContext).colors.text;
  return (
    <Box maxWidth={maxWidth}>
      <LogoIcon style={{ fill: color || ctxColor, transition: themeTransitionObject('fill') }} />
    </Box>
  );
};
