import React from 'react';
import { Box } from './Box';
import { Grid, GridProps } from './Grid';

export const InnerGrid: React.FC<GridProps & { columns?: number | number[]; as?: any }> = ({
  columns = 4,
  as,
  ...props
}) => {
  const cols = Array.isArray(columns)
    ? columns.map(col => `repeat(${col}, 1fr)`)
    : `repeat(${columns}, 1fr)`;

  return (
    <Box as={as} gridColumn="2 / -2" data-component="InnerGrid">
      <Grid gridTemplateColumns={cols} {...(props as any)} />
    </Box>
  );
};
