import React, { FC } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { flexbox, FlexboxProps } from 'styled-system';
import arrow from '../../images/icons/link-arrow.svg';
import { NoDecorationLink } from './StyledLink';

type ButtonProps = FlexboxProps;
type ButtonHoverProps = FlexboxProps & {
  href?: string;
  target?: string;
  type?: 'button' | 'submit';
  style?: CSSProperties;
  onClick?: () => void;
  disabled?: boolean;
};

export const ButtonHover: FC<ButtonHoverProps> = ({ href, target, ...rest }) => {
  if (!href) {
    return <Button {...rest} />;
  }

  return (
    <NoDecorationLink href={href} target={target}>
      <Button {...rest} />
    </NoDecorationLink>
  );
};

const Button = styled.button<ButtonProps>`
  background-color: ${props => props.theme.colors.greenPrimary};
  border: solid 1px ${props => props.theme.colors.greenPrimary};
  color: ${props => props.theme.colors.text};
  display: inline-flex;
  padding: 1rem 3.6rem 1rem 1.25rem;
  font-weight: 300;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  position: relative;
  transition: 0.3s;
  ${flexbox}

  &::after {
    content: url(${arrow});
    transform: scale(0.75);
    position: absolute;
    right: 2em;
    bottom: 0.7em;
    width: 1.4em;
    transition: 0.3s;
  }

  &:hover {
    background-color: transparent;
  }

  &:hover::after {
    right: 1em;
    transition: 0.3s;
  }

  &:active {
    color: #fff;
  }

  &:disabled {
    color: #9f9f9f;
    border-color: #9f9f9f;
    background-color: transparent;
    cursor: default;
  }
`;
