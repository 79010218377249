export function themeTransition(props: string | string[], durationInSeconds: number = 0.5): string {
  const properties = Array.isArray(props) ? props.join(',') : props;

  return `
    transition-property ${properties};
    transition-duration: ${durationInSeconds}s;
    transition-easing: cubic-bezier(0.16, 0.97, 0.46, 0.95);
  `;
}

export function themeTransitionObject(props: string, durationInSeconds: number = 0.5): string {
  return `${props} ${durationInSeconds}s cubic-bezier(0.16, 0.97, 0.46, 0.95)`;
}
