import styled from 'styled-components';
import { background, BackgroundProps, border, BorderProps, color, ColorProps, display, DisplayProps, flexbox, FlexboxProps, grid, GridProps as StyledGridProps, height, HeightProps, maxHeight, MaxHeightProps, maxWidth, MaxWidthProps, minHeight, MinHeightProps, MinWidthProps, space, SpaceProps, width, WidthProps } from 'styled-system';
import { themeTransition } from '../../theme/themeTransition';

export type GridProps = WidthProps &
  MinWidthProps &
  MaxWidthProps &
  HeightProps &
  MinHeightProps &
  MaxHeightProps &
  SpaceProps &
  DisplayProps &
  StyledGridProps &
  ColorProps &
  BorderProps &
  BackgroundProps & FlexboxProps;

export const Grid = styled.div<GridProps>`
  ${background}
  ${width};
  ${height};
  ${maxWidth};
  ${maxHeight};
  ${minHeight};
  ${maxHeight};
  ${color};
  ${space};
  ${display};
  ${grid};
  ${border};
  ${flexbox}

  ${themeTransition('background-color')}
`;

Grid.defaultProps = {
  display: 'grid',
  width: '100%',
};
