import { NavPage } from '../__generated__/graphql';
import { addURLPrefix } from './addURLPrefix';
import { extractWithLocale } from './extractWithLocale';
import { PageContext } from './PageProps';

export const getLocalizedLink = (page: NavPage | Link, locale: PageContext['locale']) => {
  if (!page) return '404';
  if ('slug' in page) {
    const slug = page.slug?.[locale];

    return page.__typename === 'SanityArticle'
      ? addURLPrefix(slug?.current, 'article', locale) ?? '404'
      : page.__typename === 'SanityCase'
      ? addURLPrefix(slug?.current, 'case', locale) ?? '404'
      : getLocalizedUri(slug?.current, locale) ?? '404';
  } else {
    return page.__typename === 'SanityRelativeUrl' ? page.relativeUrl[locale] ?? '404' : page.url ?? '404';
  }
};

export const getLocalizedUri = (uri: string | null | undefined, locale: PageContext['locale']) => {
  return `/${locale === 'sv' ? '' : locale}/${uri || '/'}`.replace(/\/{2,}/g, '/');
};

export type Link = any | null;

export const getLinkTitle = (link: Link, locale: 'sv' | 'en') =>
  link?.__typename === 'SanityArticle' ||
  link?.__typename === 'SanityCase' ||
  link?.__typename === 'SanityPage'
    ? extractWithLocale(link.name, locale)
    : link?.__typename === 'SanityOutboundLink'
    ? extractWithLocale(link.linkText, locale)
    : link?.__typename === 'SanityRelativeUrl'
    ? (link?.title && link.title[locale])
    : null;
