import BlockContent from '@sanity/block-content-to-react';
import React from 'react';
import { color } from '../../theme/color';
import { themes } from '../../theme/theme';
import { BlueLink } from './StyledLink';
import { Text } from './Text';

export type BlockContentRendererProps = {
  body: any;
};

const serializers = {
  types: {
    block: (props: any) => {
      switch (props.node.style) {
        case 'extra-small':
          return <Text {...props} fontSize={[1, 14]} color={'#000'} />;
        case 'small':
          return <Text {...props} fontSize={[1, 2]} color={'#000'} />;
        case 'normal':
          return <Text {...props} fontSize={[2, 3]} color={'#000'} />;
        case 'large':
          return <Text {...props} fontSize={[3, 4]} my={2} color={'#000'} />;
        case 'xl':
          return <Text {...props} fontSize={[5, 6]} color={'#000'} />;
      }
      return <div {...props} />;
    },
  },
  marks: {
    color: (props: any) => {
      return <span style={{ color: color[props.mark.color] }} {...props} />;
    },
    light: (props: any) => {
      return <span style={{ fontWeight: '300' }} {...props} />;
    },
    medium: (props: any) => {
      return <span style={{ fontWeight: '500' }} {...props} />;
    },
    link: (props: any) => {
      return <BlueLink href={props.mark.href} {...props} />;
    },
  },
};

export const MiniBlockContentRenderer: React.FC<BlockContentRendererProps> = ({ body }) => {
  return <BlockContent blocks={body} serializers={serializers} />;
};
