import styled from 'styled-components';
import {
  borders,
  BordersProps,
  bottom,
  BottomProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  gridColumn,
  GridColumnProps,
  gridRow,
  GridRowProps,
  layout,
  LayoutProps,
  left,
  LeftProps,
  maxHeight,
  MaxHeightProps,
  maxWidth,
  MaxWidthProps,
  minHeight,
  MinHeightProps,
  minWidth,
  MinWidthProps,
  position,
  PositionProps,
  right,
  RightProps,
  space,
  SpaceProps,
  top,
  TopProps,
  zIndex,
  ZIndexProps,
} from 'styled-system';

export type BoxProps = FlexboxProps &
  MaxHeightProps &
  MaxWidthProps &
  MinHeightProps &
  MinWidthProps &
  PositionProps &
  TopProps &
  LeftProps &
  RightProps &
  BottomProps &
  SpaceProps &
  GridColumnProps &
  GridRowProps &
  ColorProps &
  BordersProps &
  ZIndexProps &
  LayoutProps;

export const Box = styled.div<BoxProps>`
  ${borders};
  ${minWidth};
  ${maxWidth};
  ${minHeight};
  ${maxHeight};
  ${flexbox}
  ${gridColumn};
  ${gridRow};
  ${position}
  ${top}
  ${left}
  ${bottom}
  ${right}
  ${space}
  ${color}
  ${zIndex}
  ${layout}
`;
