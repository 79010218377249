import { useLayoutEffect, useState } from 'react';

export const useWindowSize = () => {
  const [state, set] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    const handler = () => set({ width: window.innerWidth, height: window.innerHeight });

    handler();

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return state;
};
