export function extractWithLocale<T extends object, TKey extends keyof NonNullable<T>>(
  field: T | null | undefined | never,
  locale: TKey
): NonNullable<T>[TKey] | null {
  if (locale == null) {
    console.error('extractWithLocale: No locale for field', field);
    return null;
  }
  if (field == null) return null;

  const value = (field as NonNullable<T>)[locale];

  if (value == null) return null;

  return value;
}
