import React, { useEffect, FC, useContext } from 'react';
import styled from 'styled-components';

import { GlobalContext } from '../GlobalContext';

interface ModalProps {
  open?: boolean;
  closeOnBackdrop?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

const Modal: FC<ModalProps> = ({ open, closeOnBackdrop = true, onClose, children }) => {
  const { dispatch } = useContext(GlobalContext);

  useEffect(() => {
    dispatch({ type: 'isModalOpen', payload: open });
  }, [open, dispatch]);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleBackdropClick = () => {
    closeOnBackdrop && handleClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Wrapper>
      <Backdrop onClick={handleBackdropClick}></Backdrop>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default Modal;

const Backdrop = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  position: absolute;
  max-width: calc(100% - 40px);
  background-color: white;
  padding: 50px 30px;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
`;
