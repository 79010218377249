import React from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import styled from 'styled-components';
import { themes } from '../theme/theme';
import { Box } from './common/Box';
import { useCurrentThemeName, useSwitchCount } from './ThemeChanger';

export const MainBg: React.FC = ({ children }) => {
  const theme = useCurrentThemeName();
  const switchCount = useSwitchCount();

  return (
    <Box>
      <TransitionGroup>
        <Transition key={`${theme}_${switchCount}`} timeout={500}>
          {status => <StyledDiv themeName={theme} status={status} />}
        </Transition>
      </TransitionGroup>

      <Box>{children}</Box>
    </Box>
  );
};

const getTransform = (status: TransitionStatus): string => {
  switch (status) {
    case 'entered':
      return `translateX(0%)`;
    case 'entering':
      return `translateX(0%)`;
    case 'exited':
      return `translateX(100%)`;
    case 'exiting':
      return `translateX(100%)`;
    case 'unmounted':
      return ``;
  }
  return ``;
};

const getOpacity = (status: TransitionStatus) => {
  if (status === 'exited') return 0;

  return 1;
};

const getBg = (themeName: 'dark' | 'light') => {
  const bgColor = themes[themeName].colors.bg;
  return bgColor;
};
const getTransition = (status: TransitionStatus) => {
  if (status === 'exited') return '';

  return 'transform 0.5s cubic-bezier(0.16, 0.97, 0.46, 0.95)';
};

const StyledDiv = styled.div<{ themeName: 'dark' | 'light'; status: TransitionStatus }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => getBg(props.themeName)};

  z-index: -1;
  transform: ${props => getTransform(props.status)};
  opacity: ${props => getOpacity(props.status)};
  transition: ${props => getTransition(props.status)};
`;
