import React, { createContext, useContext, useState } from 'react';

export const useShowBubble = () => {
  const { show } = useContext(TalksContext);
  return show;
}

export const TalksContext = createContext<{
  show: boolean | null;
}>({
  show: true,
});

export const TalksProvider: React.FC<{
  show: boolean | null;
}> = ({ show, children }) => {
  const [showState, setShowState] = useState(show);

  return (
    <TalksContext.Provider value={{ show: showState }}>
      {children}
    </TalksContext.Provider>
  );
}