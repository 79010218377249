import styled from 'styled-components';
import { themes } from '../../theme/theme';

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${props => props.color || props.theme.colors.text3};
`;

export const BlueLink = styled.a`
  text-decoration: underline;
  color: ${themes.light.colors.bluePrimary};
`;

export const NoDecorationLink = styled.a`
  text-decoration: none;
  color: ${props => props.color || props.theme.colors.text3};
`;

export const UnderlinedLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color: ${props => props.color || props.theme.colors.text3};
`;
