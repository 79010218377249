import React from 'react';
import { Box } from './common/Box';
import { H1 } from './common/Heading';
import { Text } from './common/Text';
import { AnimatedLinkWrapper } from './common/NavLink';
import styled from 'styled-components';
import arrow from '../images/icons/link-arrow.svg';
import { themeTransition } from '../theme/themeTransition';

export type BrowserNotSupportedProps = {
  locale: 'sv' | 'en';
};

const BrowserNotSupported: React.FC<BrowserNotSupportedProps> = locale => {
  let heading = "Welcome to Esatto";
  let text = "Unfortunately, we do not support Internet Explorer, you need to use a different browser. To get the best experience from esatto.se we recommend using Chrome or Edge.";

  if (locale.locale === "sv") {
    heading = "Välkommen till Esatto";
    text = "Vi stödjer tyvärr inte Internet Explorer, du behöver använda en annan webbläsare. För att få bästa upplevelsen av esatto.se så rekommenderar vi att du använder Chrome eller Edge.";
  }

  return (
    <div style={{
      height: "100vh",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <div style={{textAlign: "center"}}>
        <H1 fontSize={[6, 9, 9, 150]} fontWeight={500} mb={3}>
          {heading}
        </H1>
        <Text fontSize={4} mt={5}>
          {text}
        </Text>
        <Box display="inline-block" mt={5}>
          <AnimatedLinkWrapper position="relative">
            <Text fontSize={[3, 3, 3, 5]} mb={[2, 0]}>
              <LinkWithArrow className="link-elem" href="https://www.google.com/chrome/">
                Chrome
              </LinkWithArrow>
            </Text>
          </AnimatedLinkWrapper>
        </Box>
        <Box display="inline-block" mt={5} ml={[5,6]}>
          <AnimatedLinkWrapper position="relative">
            <Text fontSize={[3, 3, 3, 5]} mb={[2, 0]}>
              <LinkWithArrow className="link-elem" href="https://www.microsoft.com/en-us/edge">
                Edge
              </LinkWithArrow>
            </Text>
          </AnimatedLinkWrapper>
        </Box>
      </div>
    </div>
  );
};

const LinkWithArrow = styled.a`
  &::before {
    content: url(${arrow});
    position: absolute;
    left: -2em;
    bottom: -0.1em;
    width: 1em;
    transition: ${themeTransition(['left'])};
  }

  &:hover {
    &::before {
      left: -1.5em;
    }
  }
`;

export default BrowserNotSupported;
