import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { themes } from '../theme/theme';
import { getMapLink } from '../utils/getMapLink';
import { Office as OfficeModel } from '../__generated__/graphql';
import { A } from './common/A';
import { Box } from './common/Box';
import { Text } from './common/Text';
import { color } from '../theme/color';

export const Office: React.FC<{ office: OfficeModel }> = ({ office }) => {
  const { location, contact } = office;
  if (!contact || !location) return null;

  return (
    <ThemeProvider theme={themes.dark}>
      <Box mb={4}>
        <Text fontFamily="Quarto A, Quarto, Quarto B" fontWeight="500" fontSize={4} m="0">
          {contact.firstName}
        </Text>
        <LinkWrapper fontWeight={300} fontSize={1} color="text" as="a" href={'tel:' + contact.phone} style={{ textDecoration: 'none' }}>
          {contact.phone}
        </LinkWrapper>
        <Text fontWeight={300} fontSize={1}>
          {location.address}
        </Text>
        <Text fontWeight={300} fontSize={1}>
          {location.postalCode} {location.city}, {location.country}
        </Text>
        <Text fontWeight={300} fontSize={1}></Text>
        {location.coordinates && (
          <LinkWrapper as="a"
                       fontSize={1}
                       fontWeight={500}
                       target={'_blank'}
                       rel="noopener noreferrer"
                       href={getMapLink(location.coordinates)}>
            Directions
          </LinkWrapper>
        )}
      </Box>
    </ThemeProvider>
  );
};

const LinkWrapper = styled(Text)`
  &:hover {
    color: ${color.blue};
  }
`;
