import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { GlobalStyle } from '../globalStyles';
import { grid, themes } from '../theme/theme';
import { isNotNull } from '../utils/isNotNull';
import { NavPage, SiteTitleQuery } from '../__generated__/graphql';
import { Box } from './common/Box';
import { Grid } from './common/Grid';
import { Lines } from './common/Lines';
import GDPRModal from './GDPRModal';
import { CurrentLocaleProvider, LocaleLinks } from './CurrentLocale';
import { DataLayer } from './DataLayer';
import KernerRain from './eastereggs/KernerRain';
import Footer from './Footer';
import Header from './Header';
import { MainBg } from './MainBg';
import { TalksBubble } from './TalksBubble';
import { GlobalContext } from './GlobalContext';
import EsattoFaviconWhite from '../images/esatto-favicon-white.png';
import { isClientSide } from '../utils/browser';

export const NavigationLinksContext = React.createContext<NavPage[]>({} as NavPage[]);

export type LayoutProps = {
  locale: 'sv' | 'en';
  alternativeLinks?: LocaleLinks;
  forceHeaderTheme?: keyof typeof themes | false;
  overlayHeaderOverContent?: boolean;
  showFooter?: boolean;
  location: Location;
};

export const Layout: React.FC<LayoutProps> = ({
  children,
  locale,
  alternativeLinks,
  forceHeaderTheme = false,
  overlayHeaderOverContent = false,
  showFooter = true,
  location,
}) => {
  const data = useStaticQuery<SiteTitleQuery>(query);
  const { context } = useContext(GlobalContext);

  const title = data.site?.siteMetadata?.title ?? '';
  const navigation = data.sanityConfig?.navigation?.filter(isNotNull) ?? [];

  const isDarkThemeFavicon =
    isClientSide && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  const favicon = isClientSide && document.querySelector("link[rel~='icon']");

  useEffect(() => {
    if (favicon && isDarkThemeFavicon) {
      favicon.href = EsattoFaviconWhite;
    }
  }, [isDarkThemeFavicon, favicon]);

  return (
    <CurrentLocaleProvider locale={locale} alternativeLinks={alternativeLinks}>
      <DataLayer>
        <GlobalStyle isModalOpen={context.isModalOpen} />
        <Lines />
        <KernerRain />
        <TalksBubble />
        <MainBg>
          <Header
            siteTitle={title}
            navigation={navigation}
            forceHeaderTheme={forceHeaderTheme}
            location={location}
            locale={locale}
          />
          <Grid gridTemplateColumns={grid} gridTemplateRows="auto 1fr auto" minHeight="100vh">
            <EntryBox
              as="main"
              gridColumn="1 / -1"
              flex="1"
              gridRow={overlayHeaderOverContent ? '1 / span 2' : 'auto'}
              style={{ marginTop: '120px' }}
            >
              <NavigationLinksContext.Provider value={navigation}>
                {children}
              </NavigationLinksContext.Provider>
            </EntryBox>
            {showFooter && <Footer navigation={navigation} />}
          </Grid>
        </MainBg>
        <GDPRModal />
      </DataLayer>
    </CurrentLocaleProvider>
  );
};

const fadeIn = keyframes`
  from { opacity: 0; transform: translate3D(0, 30px, 0)}
`;

const EntryBox = styled(Box)`
  will-change: transform, opacity;
  animation: ${fadeIn} 0.5s;
`;

// const KernerRainLoader: React.FC = () => {
//   const [KernerRain, setKernerRain] = useState<React.FC | null>(null);

//   useEffect(() => {
//     import('./eastereggs/KernerRain').then(res => {
//       setKernerRain(res.default);
//     });
//   }, []);

//   if (!KernerRain) return null;

//   return <KernerRain />;
// };

const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }

    sanityConfig {
      navigation {
        ...NavPage
      }
    }
  }

  fragment NavPage on SanityPageOrRelativeUrl {
    __typename

    ... on SanityPage {
      id
      _key
      pageType
      name {
        en
        sv
      }
      slug {
        en {
          current
        }
        sv {
          current
        }
      }
    }
    ... on SanityRelativeUrl {
      _key
      relativeUrl {
        en
        sv
      }
      title {
        sv
        en
      }
    }
  }
`;
