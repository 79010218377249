import { Text } from './Text';

export const H1 = Text.withComponent('h1');

H1.defaultProps = {
  ...Text.defaultProps,
  fontWeight: 900,
  fontFamily: 'Quarto A, Quarto, Quarto B',
  fontSize: [5, 6, 7, 8],
  lineHeight: 1.05,
  mt: 0
};

export const H2 = Text.withComponent('h2');

H2.defaultProps = {
  ...Text.defaultProps,
  fontWeight: 900,
  fontFamily: 'Quarto A, Quarto, Quarto B',
  mt: 0,
  mb: 2,
  fontSize: [3, 4, 5],
};

export const H3 = Text.withComponent('h3');

H3.defaultProps = {
  ...Text.defaultProps,
  fontWeight: 500,
  lineHeight: 1.1,
  fontFamily: 'Quarto A, Quarto, Quarto B',
  mt: 0,
  mb: 2,
  fontSize: [3, 28],
};

export const H4 = Text.withComponent('h4');

export const H5 = Text.withComponent('h5');
