import React, { useContext } from 'react';

type PageLoad = {
  contentType: string;
  publicationDate: string;
  clientName?: string;
  tagName?: string;
};

type Event = {
  event:
    | 'interactionsVideoPlay'
    | 'interactionsReadMore'
    | 'interactionsNext'
    | 'interactionsEmail'
    | 'interactionsOutboundLink';
  contentType: string;
  publicationDate: string;
  clientName?: string;
  tagName?: string;
  videoTitle?: string;
  contentTitle?: string;
  url?: string;
};

type Promotion =
  | {
      id: string;
      name: string;
      creative: string;
      position: string;
    }
  | undefined;

type ECommerce = {
  ecommerce: {
    promoView?: {
      promotions: Promotion[];
    };
    promoClick?: {
      promotions: Promotion[];
    };
  };
};

type DataLayer = Array<Event | PageLoad | ECommerce>;

const DataLayerContext = React.createContext<Partial<DataLayer>>([]);

const PromosInViewContext = React.createContext<Partial<Promotion[]>>([]);

//  TODO: Get the data layer from gatsby gtm plugin. Somehow.
// const dataLayer = ((window as any).dataLayer = (window as any).dataLayer || []);

//  TODO: Use component in application.
export const DataLayer: React.FC = ({ children }) => {
  return (
    <PromosInViewContext.Provider value={[]}>
      {/* Provide dataLayer as value */}
      <DataLayerContext.Provider value={[]}>{children}</DataLayerContext.Provider>
    </PromosInViewContext.Provider>
  );
};

export const useDataLayerPageLoad = (data: PageLoad) => {
  return useContext(DataLayerContext).push(data);
};

export const useDataLayerEvent = (data: Event) => {
  return useContext(DataLayerContext).push(data);
};

export const useDataLayerPromoView = (data: Promotion) => {
  //  The tag plan wants a list of all the promos in view when a new promo is shown.
  const promosInView = useContext(PromosInViewContext);
  promosInView.push(data);

  return useContext(DataLayerContext).push({
    ecommerce: {
      promoView: {
        promotions: promosInView,
      },
    },
  });
};

export const useDataLayerPromoClick = (data: Promotion) => {
  return useContext(DataLayerContext).push({
    ecommerce: {
      promoClick: {
        promotions: [data],
      },
    },
  });
};
