import SecureLS from 'secure-ls';
import { isClientSide } from './browser';

const ls =
  isClientSide &&
  new SecureLS({ isCompression: false, encryptionSecret: process.env.GATSBY_LS_SECRET });

const get = (key: string) => {
  if (!isClientSide) {
    return null;
  }

  return localStorage.getItem(key);
};

const set = (key: string, value: string) => {
  if (!isClientSide) {
    return null;
  }

  return localStorage.setItem(key, value);
};

const secureGet = (key: string) => {
  if (!ls) {
    return null;
  }

  return ls.get(key);
};

const secureSet = (key: string, value: string) => {
  if (!ls) {
    return null;
  }

  return ls.set(key, value);
};

const localStorageUtils = {
  get,
  set,
  secureGet,
  secureSet,
};

export default localStorageUtils;
