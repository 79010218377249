import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle<{ isModalOpen: boolean }>`
  html {
    font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.5;
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    overflow: ${props => (props.isModalOpen ? 'hidden' : 'auto')};
  }

  svg {
    width: 100%;
    height: auto;
    vertical-align: top;
  }

  .Typist .Cursor {
    display: inline-block;

    &--blinking {
      opacity: 1;
      animation: blink 1s linear infinite;
      @keyframes blink {
        0% { opacity:1; }
        50% { opacity:0; }
        100% { opacity:1; }
      }
    }
  }

  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, strong a {
    color: unset !important;
  }
`;
