import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { grid } from '../../theme/theme';
import { themeTransition } from '../../theme/themeTransition';
import { Box } from './Box';
import { Grid } from './Grid';
import { InnerGrid } from './InnerGrid';

const Line = styled.div<{ color: string; index: number }>`
  border-left: solid 1px ${props => props.color};
  ${themeTransition(['border-left', 'border-right'])}
  transition-delay: ${props => props.index * 0.1 + 0.2}s;

  &:last-child {
    border-right: solid 1px ${props => props.color};
  }
`;

export const Lines: React.FC = () => {
  const color = useContext(ThemeContext).colors.line;

  return (
    <Box
      position="fixed"
      display={['none', 'flex']}
      justifyContent="center"
      top={0}
      left={0}
      right={0}
      bottom={0}
      style={{ pointerEvents: 'none' }}
    >
      <Grid height="100%" gridTemplateColumns={grid}>
        <InnerGrid height="100%">
          {[0, 1, 2, 3].map(index => (
            <Line key={index} index={index} color={color} />
          ))}
        </InnerGrid>
      </Grid>
    </Box>
  );
};
